body {
  background-color: #f7f7f7;
  
}

.empty-div {
  padding: 3em 0 1em 0;
  width: 100%;
}

.empty-div-half {
  padding: 1em 0;
  width: 100%;
}

.bold-class {
  font-weight: bold;
}

.p-newpost {
  color: #a0a0a0;
}

code {
  display: block;
  background: #efefef;
  min-width: 300px;
  font-size: .7em;
  overflow-x: auto;
  overflow-y: auto;
  line-height: 1.5em;
  border: #dfdfdf solid 1px;
  color: #454545;
}

.iphone-canvas {
  position: fixed;
  z-index: 999;
}

/* Modal component */

.modal {
  z-index: 99999999999999999999999999;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
}

.modal.active {
  opacity: 1;
  pointer-events: all;
}

.modal-content {
  padding: 2em;
  border-radius: 1em;
  background-color: #eeeeee;
}

.svg-menu-plank .tert a {
  font-weight: bold;
  color: #db3d3d;
  padding: 0;
  margin: 0;
}

.svg-menu-plank .tert a:hover {
  text-decoration: underline;
  color: #db3d3d;
}
/* End Modal Component */

.subenter {
  background-color: #0077cc;
}

.algn-btn {
  float: left;
  width: 100%;
  padding: 3em 0;
}

.err-message {
  position: absolute;
  color: #d60000;
  text-align: center;
  bottom: 20px;
  z-index: 9;
}

/* input uplod icon for post */


.custom-icon-input::-webkit-file-upload-button {
  visibility: hidden;
}

.custom-icon-input::before {
  content: 'Upload icon for post';
  display: inline-block;
  background: #159dc3 url( '../svg/icon-post.svg' ) 25px 17px no-repeat;
  color: #fff;
  font-size: 1.1em;
  font-weight: bold;
  border: none;
  border-radius: .6em;
  padding: 15px 35px 15px 58px;
  letter-spacing: .05em;
  -webkit-user-select: none;
  cursor: pointer;
}

/* input button upload image for post */

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.custom-file-input::before {
  content: 'Upload image';
  display: inline-block;
  background: #159dc3 url( '../svg/upload.svg' ) 25px 17px no-repeat;
  color: #fff;
  font-size: 1.1em;
  font-weight: bold;
  border: none;
  border-radius: .6em;
  padding: 15px 35px 15px 58px;
  letter-spacing: .05em;
  -webkit-user-select: none;
  cursor: pointer;
}

.style-title input[type="text"] {
  margin: 0 0 1em 0;
  border-radius: .4em;
  width: 100%;
  text-indent: .5em;
}

.testcss {
  font-size: 6em;
}

.save-note {
  position: relative;
  z-index: 1;
  border: none;
  border-radius: .6em;
  color: #fff;
  font-weight: bold;
  align-items: center;
  background: #159dc3 url(../img/i_load.gif) no-repeat;
  cursor: pointer;
  display: block;
  width: 100%;
  letter-spacing: .02em;
}

.save-note-in {
  position: relative;
  z-index: 1;
  border: none;
  border-radius: .6em;
  color: #fff;
  font-weight: bold;
  align-items: center;
  background: #159dc3 url(../img/i_load.gif) no-repeat;
  cursor: pointer;
  display: block;
  width: 100%;
  letter-spacing: .02em;
}

.i-delete {
  background: url(../img/i_delete.png) no-repeat 20px center;
}

.i-delete:hover {
  background: url(../img/i_delete_h.png) no-repeat 20px center;
}

.p-imageurl {
  padding: 2em 0;
}

.block_about {
  width: 90%;
  left: 5%;
  position: absolute;
}

.video_about {
  width: 100%;
  position: relative;
  background-color: #000;
  border-radius: 10px;
  overflow: hidden;
}

.tag-about {
  margin: 2em 0 1em 0; 
}

.title-h2 {
  color: #159dc3;
  font-size: 3em;
  margin: 1.5em 0;
}

.tag-about span {
  display: block;
  float: left;
  margin: 0 .3em 1.5em .3em;
  padding: .8em;
  background-color: #5a5a5a;
  border-radius: 6px;
  color: #dfdfdf;
}

div.top-new-post {
  padding: 3.5em 2% 1.5em 2%;
  font-size: 2.5em;
}

.top-new-post h1 {
  color: #363636;
}

.top-new-post h1,  .top-new-post p {
  padding-left: 3%;
}

.top-new-post p {
  margin: 1em 2em 1em 0;
}

.txtColumn {
  font-size: 1.8em;
  line-height: 1.5em;
  padding: 4em 2em 1em 2em;
  color: #707070;
}

.iconBlock {
  display: flex;
  background-color: #fff;
  border-radius: 1em;
  padding: .5em 0;
  width: 100%;
}

.iblock {
  vertical-align: top;
  width: 100%;
  padding: 3.5em 3em 3.5em 3%;
  display: inline-block;
}

.iconViewer {
  display: inline-block;
  min-width: 200px;
  max-width: 200px;
  height: 200px;
  background-color: #e2e2e2;
  border-radius: 100%;
  overflow: hidden;
  margin: 2.2em;
}

.iconViewer img {
  object-fit:fill;
  width: 100%;
  height: 100%;
}


.imageViewer {
  display: block;
  width: 100%;
  background-color: #eaeaea;
  text-align: center;
  z-index: -999;
  object-fit: cover;
  padding: 4em 0;
}

.imageViewer img {
  object-fit: cover;
  max-width: 90%;
}

span.css-author {
  color: #fff;
}

.css-post {
  margin: 0 10vw;
  width: 80vw;
}

div.css-checkbox {
  display: block;
  background: #eaeaea;
  height: 100px;
  width: 100%;
  padding-bottom: 1em;
  border-radius: .5em;
}

.css-checkbox p {
  display: block;
  color: #8b8b8b;
  padding: 0 0 0 5em;
  margin: -1.5em 0 0 1em;
}

.style-title .css-checkbox input {
  float: left;
  width: 40px;
  padding: 0;
  transform: scale(2);
  margin: 3.5em .4em 0 2em;
}

.style-title .css-checkbox label {
  display: inline;
  width: 40px;
  line-height: 3.6em;
  padding-left: 1em;
}

label.style-select {
  padding: 0 0 1.5em 0;
}

.style-select span{
  display: block;
  padding: 0 2em 0 0;
}
.style-select select {
  padding: .5em 0 .5em 0;
  border-radius: .4em;
  font-size: 1.1em;
  width: 100%;
  text-indent: .5em;
}
/* end input files */

input.input-file {
  padding: 1em 0;
  font-size: 1.2em;
  text-indent: 0;
}

.style-simplemde {
  font-size: 2em;
}

span.empty-block {
  display: block;
  padding: 5em 0;
}

.css-txt {
  display:block;
}

.css-txt p {
  padding: .5em 0;
  color: #595959;
}

.h2-position {
  position: absolute;
  z-index: 9999999999999;
  left: 4vw;
  bottom: 10vh;
  font-size: 1.5em;
  color: #fff;
}

div.absPostPart {
  padding: 0 0 2em 0;
}

.svg-plank {
  font: normal 1em Arial, sans-serif;
  padding: 0 .1em 2em .1em;
  margin-top: -4px;
  height: 25px;
  cursor: pointer;
  display: inline-block;
  float: right;
}

.svg-plank svg {
  padding: 1em;
}

.svg-plank:hover  {
  background: #676767;
}

.svg-menu-plank {
  z-index: -1;
}

.svg-menu-plank a {
  color: #035367;
  padding: 1em;
  padding: 1em 20px 1em 38px;
}

.svg-menu-plank a:hover {
  color: #a80000;
}

.dxnv {
  height: 80px;
transition: height 0.3s;
}

.dddd {
  height: 0px;
  overflow: hidden;
  padding: 0;
  margin: 0;
  transition: height 0.3s;
}

.svg-menu-plank h4 {
  font-weight: bold;
  padding: .6em 1em 1em 1.1em;
}

.svg-menu-plank span {
  margin-left: 10px;
}

.css-delete {
  background: url(../svg/delete.svg) no-repeat;
  background-position: 8px 12px;
}

a.delete-comm {
  display: block;
  margin-top: 2em;
  padding: .5em 0;
  color:#bebebe;
  font-size: .8em;
  margin-bottom: -2em;
}

a.delete-comm:hover {
  color: #4d4d4d;
}

.css-edit {
  background: url(../svg/edit.svg) no-repeat;
  background-position: 8px 13px;
}

.css-plank {
  background-color: #c2f3ff;
  display: inline-block;
  overflow: hidden;
  border-radius: 6px;
  margin: 2em  0 2em 0;
}

.css-plank .fp  {
  opacity: 0.6;
}

.css-plank > a {
  display: inline-block;
  background: none;
  text-decoration: none;
  color: #035367;
  padding: 1.1em;
}

.css-plank > a:hover {
  color: #fff;
  background-color: #1798bb;
}

/* css for fPost.js */

.fPost {
  position: absolute;
  width: 100%;
  height: 100%;
}

.txtfPOst {
  position: absolute;
  z-index: 9;
  margin-left: 10%;
  padding-top: 20vh;
}

.txtfPOst h1 {
  opacity: .8;
  color: #fff;
  /* mask-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/5383/splatter-mask_1_1.png"); */
}

.rt-lenta-post  {
  position: absolute;
  float: right;
}

.rt-lenta-post a {
  display: block;
  color: #fff;
  font-size: 1.3em;
  padding: 1em 0;
  line-height: 1.5em;
}

.rt-lenta-post a:hover {
  color: #e1c2af;
}
/* end input files */


/* wrapper signup signin */

.wrapper {
  max-width: 500px;
  margin: 0 auto;
  border-radius: 1em;
  background-color: rgba(69, 214, 255, .1);
  backdrop-filter: blur(20px);
}

/* end wrapper */


.ErrorBlock {
  width: 40%;
  line-height: 1.4em;;
  height: 100px;
  font-weight: bold;
  color:rgba(0, 0, 0, 0.6);
  margin: 15% 10% 0 5%;
  position: absolute;
  font-size: 4em;
}

/* loading block */

.loading-signinup {
  width: 50%;
  margin-top: 20vh;
  margin-left: 25%;
}

.loading-signinup p {
  font-size: 2em;
  text-align: center;
}

/* UserForm style */

.css-userform {
  padding-top: 15vh;
  position: absolute;
}

.css-userform h2 {
  text-align: left;
  color: #fff;
}

.css-userform button {
  width: 100%;
}

/* start myposts li block */

.myposts-li-block {
  padding-top: 3em;
}

.myposts-li-block li {
  display: block;
  font-weight: bold;
  border-bottom: 1px solid #c4c4c4;
}

.myposts-li-block a h1 {
  color: #0286ab;
  padding: .2em 0;
}

.myposts-li-block a {
  color: #0286ab;
}

.myposts-li-block a:hover h1 {
  color: #363636;
}

.clickMore {
  background-color: #e6e6e6;
  padding: 2em 0;
  text-align: center;
  font-weight: bold;
  font-size: 1.2em;
  cursor: pointer;
}

/* start comments block */

.top_commbocks {
  display: inline-block;
  width: 100%;
  max-width: calc(800px + 2em);
}

.top_commbocks h3 {
  color:#979797;
  float: left;
  font-weight: normal;
}

.comments-block {
  display: block;
  margin:  -4em 10% 5% 10%;
  width: 80%;
  display: inline-block;
}

.comments-block form {
  padding-bottom: 4em;
}


.comments-block textarea {
  border-radius: 1em;
  height: 100px;
  max-width: 800px;
  border:none;
  display: block;
  font-size: 1.4em;
  margin: 0 0 2em 0;
  width: calc(100% - 1em);
  padding: .5em;
}

.comments-block button {
  color: #fff;
  background-color: #0077cc;
  border: none;
  font-size: 1em;
  border-radius: 1em;
  padding:  .5em 1.5em;
  float: left;
}

.length-comments {
  float: right;
  margin-bottom: 1.5em;
  color: #b9b9b9;
}

.comment-block {
  padding: 1.6em 0;
  border-bottom: 1px solid #bebebe;
}
.comments-block h3 {
  font-size: 1.2em;
  float: left;
  display: inline;
  padding-right: 1em;
}

.comment-block span {
  color: #8d8d8d;
  
}

.comment-block p {
  color: #474747;
  font-size: 1.2em;
  padding-top: .6em;
}

.form-comment {
  margin-top: 5em;
  max-width: 800px;
}

.author-comment {
  font-weight: bold;
  padding-right: 1em;
  font-size: 1.1em;
}
/* end comments block */

/* start futer */

.futer-block {
  padding: 5em 3em;
}

.futer-block h3 {
  margin: 5 em 2em;
}

/* end futer */


/* start cat list news */

.cats_block {
  padding-top: 3em;
}

.cats_block li {
  display: block;
  font-weight: bold;
  border-bottom: 1px solid #c4c4c4;
}

.cats_block li h1 {
  padding: .2em 0;
}

.cats_block li h1:hover {
  color: #363636;
}

.li-post-flex {
  display:flex;
  margin-top: .3em;
}

.iconPost img.no-avatar {
  position: absolute;
  float: left;
  object-fit:none;
}

.iconPost {
  position: absolute;
  display: block;
  float: left;
  background-color: #e2e2e2;
  border-radius: 100%;
  overflow: hidden;
  margin: 0 2.2em 2.2em 0;
}

.iconPost img {
  object-fit:fill;
  width: 100%;
  height: 100%;
}

.css-plank-cat {
  display: block;
  float: left;
  padding: 1em 0;
  margin-top: 1em;
  background-color: #c2f3ff;
  border-radius: 8px;
  font-weight: normal;
  overflow: hidden;
}

.css-plank-cat span {
  padding: 2em .7em;
}

.css-plank-cat a {
  padding: 1em .7em;
}

.css-plank-cat a:hover {
  background-color: #1798bb;
  color: #fff;
}

.cats_block a  {
  color: #0286ab;
}

.cats_block a:hover li  {
  color: #47bc00;
}

.top-block {
  padding: 3em 4% 0 0;
}

.top-block h2 {
  font-family: Arial, sans-serif;
  min-width: fit-content;
  color: #363636;
}

.all-post-block {
  width: 100%;
  display: block;
  padding-top: 1em;
}

.all-post-block::before,
.all-post-block::after {
  content: "";
  display: table;
}
.all-post-block::after {
  clear: both;
}
.all-post-block {
  clear: both;
  zoom: 1;
}

.all_post {
  padding: 1.2em 2em;
  float: left;
  background-color: #00b5e3;
  color: #fff;
  border-radius: 10px;
}

span.uname_weight {
  font-weight: bold;
}

div.xclose:hover {
		-o-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
    opacity: 1;
		transform: rotate(360deg);
	}

  .xcls-1 {
    z-index: 0;
  }

  .xcls-1, .xcls-2 {
    fill-rule: evenodd;
  }

  .xcls-2 {
    fill: #00b5e3;
    z-index: 1;
    transition: all .3s;
    transform-origin: 50% 50%;
  }

  .xcls-1:hover + .xcls-2 {
    -o-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
    opacity: 1;
		transform: rotate(360deg);
  }

  .small-menu-01 {
    fill: none;
    stroke: #8ed9ed;
    stroke-width: 2px;
  }

  .small-menu-02 {
    fill: #8ed9ed;
    fill-rule: evenodd;
  }

  .css-back{
    right: 7%;
    position: fixed;
    top: 15%;
    height: 80px;
    width: 50px;
    z-index: 999;
    opacity: .8;
  }

  .css-back:hover::after {
    content: "back";
    z-index: 1;
    background: rgba(255,255,230,0.9);
    font-family: Arial, sans-serif;
    font-size: 11px;
    padding: 5px 10px;
    border-radius: 3px;
  }

  .loader-canvas {
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
  }

  .bg-canvas {
    display: block;
    position: fixed;
    z-index: -1;
    width: 100%;
    height: 100%;
  }

  .bg-canvas img {
    height:100vh;
  }

  .black_bg{
    position: absolute;
    background-color: #000;
    width: calc(100vw - 22px);
    z-index: -2;
    height: 100vh;
}

.htcanvas {
  z-index: -99999;
  height: 100%;
  width: 100%;
  margin-bottom: 4em;
  border-radius: 1em;
}

.post-canvas {
  align-content: center;
  position: relative;
  float: left;
  width: 100%;
}

.post-canvas canvas {
  position: relative;
  height: 60vh;
}
/* POST style   */

.img-post img {
  max-width: 100%;
}

.imgStyle {
  float: left;
  width: 100%;
  padding: 0;
  overflow: hidden;
  border-radius: 1em;
}

.imgStyle img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 1em;
}

input.script-url {
  position: relative;
  float: left;
  transform: scale(2.0);
}

.script-url label {
  position: relative;
  margin-left: 8em;
  transform: scale(2.0);
}

@media screen and (min-width: 376px) and (max-width: 576px)  {
  code {
    margin: 2.5em 0;
    width: calc(100% - 2em);
    padding: 1em;
    display:block;
  }

  .length-comments {
    float: left;
    padding-top: .5em;
  }

  .comments-block {
    margin-top: -6em;
  }
/* FIRST PAGE */
  .txtfPOst h1 {
    margin-top: 50vw;
    font-size: 3em;
    width: 90%;
  }
  
  .rt-lenta-post {
    display: none;
  }

  .iphone-canvas {
    width: 90vw;
    left: 5%;
    height:60vh;
  }
/* END FIRST PAGE */

  .myposts-li-block a h1 {
    font-size: 2.3em;
  }

  .post-cont {
    margin-left: 110px;
  }

  .iconPost {
    min-width: 90px;
    max-width: 90px;
    height: 90px;
    margin-top: .7em;
  }

  .css-plank-cat {
    font-size: .8em;
  }

  .myposts-li-block li {
    padding: 1em 5% 5.5em;
}

.all_post{
  margin: 3em 0 0 5%;
}

.top-block h2 {
  padding: 1.5em 0 0 0;
  font-size: 2.2em;
  line-height: 1.1em;
  font-weight: bold;
}

.css-txt {
  display:block;
  padding: 4em 0 2em 0;
  font-size: 1em;
  line-height: 1.3em;
}

.css-txt a {
  padding: 1px;
  background: #45abc5;
}

.img-post {
  padding:1em 0;
}

.imgStyle {
  max-height: 450px;
  margin: 1em 0 2em 0;
}

.cats_block li h1 {
  padding: .2em 0;
  font-size: 2.5em;
}

.cats_block li {
  padding: 1.2em 5% 5em 5%;
}

.cats_block ul {
  margin-top: 8.5em;
}

.top-new-post h1 {
  font-size: 1.8em;
}

.p-newpost {
  font-size: .7em;
}

/* wrapper sign up & in */
.wrapper {
  padding: 2.5em;
}

.css-userform {
  padding-top: 18vh;
  width: 80vw;
  margin: 0 10%;
}

.css-userform h2 {
  font-size: 2.4em;
}

label {
  margin-top: 1.3em;
  font-size: 1.2em;
}

input[type="text"], #password {
  font-size: 1.1em;
}

.css-userform button {
  width: 100%;
}

.save-note, .save-note-in {
  padding: 19px 10px 19px 35px;
  font-size: 1em;
  background-size: 1.4em;
}
.save-note {
  background-position: calc(50% - 65px) calc(50% - 2px);
}
.save-note-in {
  background-position: calc(50% - 50px) calc(50% - 2px);
}

.err-message {
    width: 90%;
    left: 5%;
    bottom: -6em;
}

.up { 
  bottom: -4em;
}
/* end wrapper sign in & up */

.iconPost img.no-avatar {
  object-fit: fill;
  margin: 25%;
  width: 50%;
  height: 50%;
}
}

@media  screen and (min-width: 576px) and (max-width: 960px) {

  code {
    margin: 2em 0;
    width: calc(100% - 2em);
    padding: 1em;
    float: left;
  }

.comments-block {
  margin-top: .2em;
} 

  /* FIRST PAGE */
  .txtfPOst h1 {
    font-size: 4em;
    width: 50vw;
    line-height: 1.1em;
  }
  .rt-lenta-post {
    display: flex;
    width: 95vw;
    float: left;
    top: 75vh;  
    left: 5vw;
  }
  .rt-lenta-post div {
    display: block;
    width: 30%;
    margin-right: 3%;
    float: left;
  }

  .iphone-canvas {
    right: 2vw;
    top: -70px;
    width: 55vw;
    height: 100vh;
  }
/* END FIRST PAGE */

  .myposts-li-block a h1 {
    font-size: 3em;
  }

  .post-cont {
    margin-left: 150px;
  }

  .iconPost {
    margin-top: 17px;
    min-width: 120px;
    max-width: 120px;
    height: 120px;
    margin-top: 1em;
  }

  .css-plank-cat {
    font-size: .9em;
  }

  .myposts-li-block li {
    padding: 1em 5% 5.5em;
}

.all_post{
  margin: 4em 0 0 5%;
}

.imgStyle {
  max-height: 450px;
  margin: 1em 0 2em 0;
}

.top-block h2 {
  padding: 1em 0 0 0;
  font-size: 6em;
  line-height: 1em;
  font-weight: bold;
}

.css-txt {
  display:block;
  padding: 3em 0 2em 0;
  font-size: 1.6em;
  line-height: 1.6em;
}

.img-post {
  padding:1em 0;
}

.cats_block li h1 {
  padding: .2em 0;
  font-size: 3em;
}

.cats_block li {
  padding: 1.2em 5% 6em 5%;
}

.cats_block ul {
  margin-top: 9.5em;
}

.top-new-post h1 {
  font-size: 2.5em;
}

.p-newpost {
  font-size: 1em;
}

/* wrapper sign up & in */
.wrapper {
  padding: 3em;
}

.css-userform {
  padding-top: 20vh;
  width: 60vw;
  margin: 0 20%;
}

.css-userform h2 {
  font-size: 2.7em;
}

label {
  margin-top: 1.5em;
  font-size: 1.2em;
}

input[type="text"], #password {
  font-size: 1.2em;
}

.css-userform button {
  width: 100%;
}

.save-note, .save-note-in {
  padding: 19px 10px 19px 35px;
  font-size: 1em;
  background-size: 1.4em;
}
.save-note {
  background-position: calc(50% - 65px) calc(50% - 2px);
}
.save-note-in {
  background-position: calc(50% - 50px) calc(50% - 2px);
}

.err-message {
    width: 90%;
    left: 5%;
    bottom: -6em;
}

.up { 
  bottom: -4em;
}
/* end wrapper sign in & up */
}

@media  screen and (max-width: 376px) {

  code {
    width: calc(100% - 2em);
    margin: 1em 0;
    padding: 1em;
    font-size: .6em;
  }

  .comments-block {
    margin-top: -7em;
    display:inline-block;
  }

.length-comments {
  float: left;
  padding-top: 1em;
}

  /* FIRST PAGE */
  .txtfPOst h1 {
    margin-top: 75vw;
    line-height: 1.2em;
    font-size: 2.5em;
    width: 90%;
  }
  .rt-lenta-post {
    display: none;
  }

  .iphone-canvas {
    left: 5vw;
    top: 15vw;
    width: 90%;
    height: 50vh;
  }

  .txtfPOst {
    width: 90%;
  }

  .rt-lenta-post {
    display: none;
  }


/* EDIT PAGE */

.iconBlock {
  display: block;
  padding: 0 1em;
}

.iblock button {
  margin: 2em 0;
  float:none;
}

.p-imageurl {
  padding: 0 1em 0 0;
}

.iblock {
  display: block;
  padding-top: 1.5em;
}

/* END EDIT PAGE */

/* END FIRST PAGE */

  .myposts-li-block a h1 {
    font-size: 1.4em;
  }

  .post-cont {
    margin-left: 90px;
  }

  .iconPost {
    min-width: 70px;
    max-width: 70px;
    height: 70px;
    margin-top: .3em;
  }

  .css-plank-cat {
    font-size: .7em;
  }

  .myposts-li-block li {
    padding: 1em 5% 4.5em;
}

.all_post{
  margin: 3em 0 0 5%;
  padding: 1em 1.8em;
  font-size: .9em;
}

.css-plank-cat span {
  padding: 2em 1em 2em 0;
}

.top-block h2 {
  padding: 1.6em 0 0 0;
  font-size: 2.2em;
  line-height: 1.2em;
  font-weight: bold;
}

.css-txt p {
  font-size: .5em;
  line-height: 1.4em;
}

.css-txt {
  display:block;
  padding: 2.5em 0 2em 0;
  font-size: 2em;
  line-height: 1.3em;
}

.img-post {
  padding:1em 0;
}

.imgStyle {
  max-height: 500px;
  margin: 1em 0 2em 0;
}

.css-plank {
  font-size: .9em;
}

.cats_block li h1 {
  padding: .2em 0;
  font-size: 2em;
}

.cats_block li {
  padding: 1.2em 5% 4.3em 5%;
}

.cats_block ul {
  margin-top: 7em;
}

.top-new-post h1 {
  font-size: 1.5em;
}

.p-newpost {
  font-size: .6em;
}

/* wrapper sign up & in */
.wrapper {
  padding: 2em;
}

.css-userform {
  padding-top: 15vh;
  width: 90vw;
  margin: 0 5%;
}

.css-userform h2 {
  font-size: 1.5em;
}

label {
  margin-top: 1em;
  font-size: 1.2em;
}

input[type="text"], #password {
  font-size: 1em;
}

.css-userform button {
  width: 100%;
}

.save-note, .save-note-in {
  padding: 16px 10px 16px 35px;
  font-size: .9em;
  background-size: 1.4em;
}
.save-note {
  background-position: calc(50% - 65px) calc(50% - 2px);
}
.save-note-in {
  background-position: calc(50% - 50px) calc(50% - 2px);
}

.err-message {
    width: 90%;
    left: 5%;
    bottom: -6em;
}

.up { 
  bottom: -4em;
}
/* end wrapper sign in & up */

.iconPost img.no-avatar {
  object-fit: fill;
  margin: 25%;
  width: 50%;
  height: 50%;
}

}
@media  screen and (min-width: 960px) and (max-width: 1200px) {

  code {
    width: calc(100% - 2em);
    padding: 2em;
    float: left;
    margin: 3em 0;
    font-size: .9em;
  }

  .comments-block {
    margin-top: 0;
  }
  /* FIRST PAGE */
  .txtfPOst h1 {
    font-size: 5em;
    width: 45vw;
  }
  .rt-lenta-post {
    display: block;
    width: 20vw;
    right: 2vw;
    top: 20vh;
  }

  .iphone-canvas {
    left: 28vw;
    top: 32px;
    width: 55vw;
    height: calc(100vh - 32px);
  }
/* END FIRST PAGE */

  .myposts-li-block a h1 {
    font-size: 3em;
  }

  .css-plank-cat {
    font-size: 1em;
  }

  .post-cont {
    margin-left: 160px;
  }

  .iconPost {
    margin-top: 1em;
    min-width: 130px;
    max-width: 130px;
    height: 130px;
    margin-top: 1em;
  }

  .myposts-li-block li {
    padding: 1em 5% 7em;
}

.all_post{
  margin: 4em 0 0 5%;
}

.top-block h2 {
  padding: .8em 0 0 0;
  font-size: 6em;
  line-height: 1em;
}

.css-txt {
  display:block;
  margin-top: 2em;
  font-size: 1.4em;
  line-height: 1.6em;
}

.img-post {
  padding: 2em 0;
}

.imgStyle {
  max-height: 500px;
  margin: 2em 0 5em 0;
}

.cats_block li h1 {
  padding: .2em 0;
  font-size: 4em;
}

.cats_block li {
  padding: 1.5em 5% 6.5em 5%;
}

.cats_block ul {
  margin-top: 9.5em;
}

.top-new-post h1 {
  font-size: 2.5em;
}

.p-newpost {
  font-size: 1.1em;
}

/* wrapper sign up & in */
.wrapper {
  padding: 3em 5em 3em 5em;
}

.css-userform {
  padding-top: 18vh;
  width: 50vw;
  margin: 0 25%;
}

.css-userform h2 {
  font-size: 2.8em;
}

label {
  margin-top: 1.5em;
  font-size: 1.2em;
}

input[type="text"], #password {
  font-size: 1.2em;
}

.css-userform button {
  width: 100%;
}

.save-note, .save-note-in {
  padding: 19px 10px 19px 35px;
  font-size: 1em;
  background-size: 1.4em;
}
.save-note {
  background-position: calc(50% - 65px) calc(50% - 2px);
}
.save-note-in {
  background-position: calc(50% - 50px) calc(50% - 2px);
}

.err-message {
    width: 90%;
    left: 5%;
    bottom: -6em;
}

.up { 
  bottom: -4em;
}
/* end wrapper sign in & up */

}

@media  screen and (min-width: 1200px) and (max-width: 1366px) {

  code {
    width: calc(100% - 4em);
    margin: 2.5em 0;
    padding: 2em;
    float: left;
  }

.comments-block {
  margin-top: 0;
}

    /* FIRST PAGE */
  .txtfPOst h1 {
    font-size: 6em;
  }
  .rt-lenta-post {
    display: block;
    width: 20vw;
    right: 2vw;
    top: 20vh;
  }

  .iphone-canvas {
    left: 40vw;
    top: 72px;
    width: 35vw;
    height: calc(100vh - 72px);
  }

  .txtfPOst {
    width: 50vw;
  }
/* END FIRST PAGE */

  .myposts-li-block a h1 {
    font-size: 4em;
  }

  .post-cont {
    margin-left: 200px;
  }

  .iconPost {
    min-width: 160px;
    max-width: 160px;
    height: 160px;
    margin-top: 1em;
  }

  .css-plank-cat {
    font-size: 1em;
  }

  .myposts-li-block li {
    padding: 2em 5% 8em;
}

  .all_post{
    margin: 5em 0 3em 5%;
  }

  .top-block h2 {
    padding: .8em 0 0 0;
    font-size: 7em;
    line-height: .9em;
  }

  .css-txt {
    display:block;
    padding: 2em 0 2em 0;
    margin-top: 2em;
    font-size: 1.8em;
    line-height: 1.3em;
  }

  .img-post {
    padding: 2em 0;
  }

  .imgStyle {
    max-height: 450px;
    margin: 2em 0 5em 0;
  }

  .cats_block li h1 {
    padding: .2em 0;
    font-size: 4em;
  }

  .cats_block li {
    padding: 2.5em 5% 7em 5%;
  }

  .cats_block ul {
    margin-top: 10em;
  }

  .top-new-post h1 {
    font-size: 2.5em;
  }

  .p-newpost {
    font-size: 1em;
  }

/* wrapper sign up & in */
.wrapper {
    padding: 3em 7em 3em 6em;
}

.css-userform {
  padding-top: 18vh;
  width: 50vw;
  margin: 0 25%;
}

.css-userform h2 {
  font-size: 3.5em;
}

label {
  margin-top: 1.5em;
  font-size: 1.4em;
}

input[type="text"], #password {
  font-size: 1.4em;
}

.css-userform button {
  width: 100%;
}

.save-note, .save-note-in {
  padding: 20px 10px 20px 35px;
  font-size: 1.1em;
  background-size: 1.2em;
}
.save-note {
  background-position: calc(50% - 75px) calc(50% - 2px);
}
.save-note-in {
  background-position: calc(50% - 60px) calc(50% - 2px);
}

.err-message {
    width: 90%;
    left: 5%;
    bottom: -5em;
}

.up { 
  bottom: -4em;
}
/* end wrapper sign in & up */
}



@media screen and (min-width: 1336px) {
  code {
    width: calc(100% - 2em);
    padding: 2em;
    float: left;
    margin: 3em 0;
  }

.comments-block {
  margin-top: 1em;
}

  .txtColumn {
    column-count: 2;
    column-width: 40%;
    column-gap: 7%;
  }

  
}

@media screen and (max-width: 1336px) {
  .txtColumn {
    column-count: none;
  }
  
}

@media  screen and (min-width: 1366px) and (max-width: 1920px)  {

  code {
    margin: 3em 0;
    padding: 2em;
    width: calc(100% -4em);
    display: inline-block;
  }



  /* FIRST PAGE */
  .txtfPOst h1 {
    font-size: 7em;
  }
  .rt-lenta-post {
    display: block;
    width: 20vw;
    right: 2vw;
    top: 20vh;
  }

  .iphone-canvas {
    left: 40vw;
    top: 72px;
    width: 35vw;
    height: calc(100vh - 72px);
  }

  .txtfPOst {
    width: 50vw;
  }
/* END FIRST PAGE */

.myposts-li-block a h1 {
  font-size: 4em;
}

.post-cont {
  margin-left: 230px;
}

.iconPost {
  min-width: 180px;
  max-width: 180px;
  height: 180px;
  margin-top: 1em;
}

.css-plank-cat {
  font-size: 1em;
}

.all_post{
  margin: 5em 0 3em 5%;
}

.myposts-li-block li {
  padding: 2em 5% 8em;
}

.top-block h2 {
  padding: .8em 0 0 0;
  font-size: 7em;
  line-height: 1em;
}

.css-txt {
  display:block;
  padding: 2em 0 2em 0;
  margin-top: 2em;
  font-size: 2em;
  line-height: 1.3em;
}

.img-post {
  padding: 2em 0;
}

.imgStyle {
  max-height: 500px;
  margin: 2em 0 5em 0;
}

.cats_block li h1 {
  padding: .3em 0;
  font-size: 4em;
}

.cats_block li {
  padding: 2em 5% 7.5em 5%;
}

.cats_block ul {
  margin-top: 10em;
}

.top-new-post h1 {
  font-size: 2.5em;
}

.p-newpost {
  font-size: 1em;
}

/* wrapper sign up & in */
.wrapper {
  padding: 3em 7em 3em 6em;
}

.css-userform {
padding-top: 18vh;
width: 50vw;
margin: 0 25%;
}

.css-userform h2 {
font-size: 3.5em;
}

label {
margin-top: 1.5em;
font-size: 1.4em;
}

input[type="text"], #password {
font-size: 1.4em;
}

.css-userform button {
width: 100%;
}

.save-note, .save-note-in {
padding: 20px 10px 20px 35px;
font-size: 1.1em;
background-size: 1.2em;
}
.save-note {
background-position: calc(50% - 75px) calc(50% - 2px);
}
.save-note-in {
background-position: calc(50% - 60px) calc(50% - 2px);
}

.err-message {
  width: 90%;
  left: 5%;
  bottom: -5em;
}

.up { 
bottom: -4em;
}
/* end wrapper sign in & up */
}

@media screen and (min-width: 1920px) {

  code {
    width: 90%;
    display: inline-block;
  }
  /* FIRST PAGE */
  .txtfPOst h1 {
    font-size: 7em;
  }
  .rt-lenta-post {
    display: block;
    width: 20vw;
    right: 2vw;
    top: 20vh;
  }

  .iphone-canvas {
    left: 40vw;
    top: 72px;
    width: 35vw;
    height: calc(100vh - 72px);
  }

  .txtfPOst {
    width: 50vw;
  }

/* END FIRST PAGE */

  .myposts-li-block a h1 {
    font-size: 4em;
  }

  .post-cont {
    margin-left: 230px;
  }

  .iconPost {
    min-width: 180px;
    max-width: 180px;
    height: 180px;
    margin-top: 1.5em;
  }

  .css-plank-cat {
    font-size: 1em;
  }

  .all_post{
    margin: 5em 0 3em 5%;
  }

  .myposts-li-block li {
    padding: 2.5em 5% 8.5em;
}

  .top-block h2 {
    padding: .8em 0 0 0;
    font-size: 7.5em;
    line-height: 1.2em;
  }

  .css-txt {
    display:block;
    padding: 2em 0 2em 0;
    margin-top: 2em;
    font-size: 2em;
    line-height: 1.3em;
  }

  .img-post {
    padding: 2em 0;
  }

  .imgStyle {
    max-height: 800px; 
    margin: 2em 0 5em 0;
  }

  .cats_block li h1 {
    padding: .4em 0;
    font-size: 4em;
  }

  .cats_block li {
    padding: 2.5em 5% 8em 5%;
  }

.cats_block ul {
  margin-top: 10em;
}

.top-new-post h1 {
  font-size: 2.5em;
}

.p-newpost {
  font-size: 1em;
}

.css-userform h2 {
  font-size: 4em;
}

.wrapper {
  padding: 3em 7em 4em 6em;
}

label {
  margin-top: 2em;
  font-size: 1.5em;
}

.css-userform button {
  margin-top: 1.3em;
  width: 100%;
}

/* wrapper sign up & in */
.wrapper {
  padding: 3em 7em 3em 6em;
}

.css-userform {
padding-top: 18vh;
width: 50vw;
margin: 0 25%;
}

.css-userform h2 {
font-size: 3.5em;
}

label {
margin-top: 1.5em;
font-size: 1.4em;
}

input[type="text"], #password {
font-size: 1.4em;
}

.css-userform button {
width: 100%;
}

.save-note, .save-note-in {
padding: 20px 10px 20px 35px;
font-size: 1.1em;
background-size: 1.2em;
top: -1em;
}
.save-note {
background-position: calc(50% - 75px) calc(50% - 2px);
}
.save-note-in {
background-position: calc(50% - 60px) calc(50% - 2px);
}

.err-message {
  width: 90%;
  left: 5%;
  bottom: -5em;
}

.up { 
bottom: -4em;
}
/* end wrapper sign in & up */
}

